import { FC } from 'react';

type Props = {};

export const Footer: FC<Props> = ({ children }) => {
	return (
		<footer className="footer page__footer">
			<div className="row-main">{children}</div>
		</footer>
	);
};
