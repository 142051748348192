import { FC, ReactNode } from 'react';

type Props = {
	title?: ReactNode;
};

export const FooterMenuList: FC<Props> = ({ children, title }) => {
	return (
		<div className="m-footer__list">
			{title && <p className="m-footer__title">{title}</p>}
			{children}
		</div>
	);
};
