import { useEffect, useState } from 'react';
import { MenuSecondary } from '../ui/layout/MenuSecondary';
import { MenuSecondaryItem } from '../ui/layout/MenuSecondaryItem';
import { MenuSecondaryLink } from '../ui/layout/MenuSecondaryLink';
import { slugify } from './slugify';

type Props = {
	condensed?: boolean;
	items: string[];
};

export const Submenu = ({ condensed = false, items }: Props) => {
	const [activeIndex, setActiveindex] = useState<number | null>(null);

	useEffect(() => {
		let elements = items.map(item => document.getElementById(slugify(item)));
		const onScroll = () => {
			const bounds = elements.map(el => el?.getBoundingClientRect());
			const { i } = bounds.reduce(
				(res: { i: number | null; distance: number }, curr, i) => {
					const distance = curr ? Math.abs(curr.top) : Infinity;
					if (curr && res.distance > distance) {
						return { i, distance };
					} else return res;
				},
				{ i: null, distance: Infinity }
			);
			setActiveindex(i);
		};
		window.addEventListener('scroll', onScroll, false);
		onScroll();
		return () => {
			window.removeEventListener('scroll', onScroll, false);
			elements = [];
		};
	}, [items]);

	return (
		<MenuSecondary isCondensed={condensed}>
			{items.map((item, i) => (
				<MenuSecondaryItem key={i}>
					<MenuSecondaryLink href={`#${slugify(item)}`} isActive={activeIndex === i}>
						{item}
					</MenuSecondaryLink>
				</MenuSecondaryItem>
			))}
		</MenuSecondary>
	);
};
