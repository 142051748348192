import { FunctionComponent } from 'react';
import classNames from 'classnames';

type Props = {
	width?: number;
	height?: number;
	nativeWidth: number;
	nativeHeight: number;
	id?: string;
};

export const IconWrapper: FunctionComponent<Props> = ({ width, height, nativeWidth, nativeHeight, id, children }) => {
	const svgWidth = width == null && height == null ? '100%' : `${width || nativeWidth}px`;
	const svgHeight =
		width == null && height == null
			? '100%'
			: `${height || (nativeHeight / nativeWidth) * (width || nativeWidth) || nativeHeight}px`;
	const classes = classNames('icon-svg', id ? `icon-svg--${id}` : '');

	return (
		<span className={classes}>
			<svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${nativeWidth} ${nativeHeight}`}>
				{children}
			</svg>
		</span>
	);
};
