import { FC, Children } from 'react';

type Props = {};

export const MenuLangList: FC<Props> = ({ children }) => {
	return (
		<ul className="m-lang__list">
			{Children.map(children, (child) => (
				<li className="m-lang__item">{child}</li>
			))}
		</ul>
	);
};
