import { DefaultSeo } from 'next-seo';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { generateLangUrl, langMap } from '../../lib/localize';
import { GetPageQuery, Maybe } from '../../types/codegen';
import { HeaderLogo } from '../ui/header/Logo';
import { LogoPixii } from '../ui/icons/LogoPixii';
import { Header } from '../ui/layout/Header';
import { Page } from '../ui/layout/Page';
import { RowMain } from '../ui/layout/RowMain';
import { MenuLang } from '../ui/MenuLang/MenuLang';
import { MenuLangLink } from '../ui/MenuLang/MenuLangLink';
import { MenuLangList } from '../ui/MenuLang/MenuLangList';
import { MainMenu } from './MainMenu';
import { PageFooterMenu } from './PageFooterMenu';
import { Submenu } from './Submenu';

type Props = {
	menu?: GetPageQuery['mainMenu'] | null;
	isLogoClickable?: boolean;
	disableExitPopup?: boolean;
	submenu?: string[];
	localizedSlugs?:
		| {
				locale: string;
				slug: Maybe<string>;
		  }[]
		| null;
	displayFooterMenu?: boolean;
};

export const WebsiteLayout: FC<Props> = ({
	children,
	menu,
	submenu,
	isLogoClickable = true,
	localizedSlugs,
	displayFooterMenu = true,
}) => {
	const [isScrolled, setScrollState] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			setScrollState(window.pageYOffset > 50);
		};

		onScroll();

		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const { locales, locale } = useRouter();

	return (
		<Page isMenuOpened={isMenuOpened}>
			<DefaultSeo
				openGraph={{
					type: 'website',
					site_name: 'Pixii',
					images: process.env.URL_CDN_IMG
						? [{ url: `${process.env.URL_CDN_IMG}/img/og-fb.jpg`, width: 1200, height: 630, alt: 'Pixii' }]
						: [],
				}}
				titleTemplate="%s | Pixii"
				additionalMetaTags={
					process.env.URL_CDN_IMG
						? [
								{
									property: 'twitter:card',
									content: 'summary_large_image',
								},
								{
									property: 'twitter:image',
									content: `${process.env.URL_CDN_IMG}/img/og-tw.jpg`,
								},
						  ]
						: []
				}
			/>
			<MenuLang>
				<RowMain variants="xl">
					<MenuLangList>
						{locales?.map((lang) => (
							<MenuLangLink
								isActive={lang === locale}
								key={lang}
								href={generateLangUrl(lang, localizedSlugs)}
							>
								{langMap.get(lang) || lang.toUpperCase()}
							</MenuLangLink>
						))}
						<a className="m-lang__link" href="https://www.pixii.com/">
							EN
						</a>
					</MenuLangList>
				</RowMain>
			</MenuLang>
			<Header isCondensed={isScrolled}>
				<RowMain variants="xl">
					<HeaderLogo title="Pixii">
						{isLogoClickable ? (
							<Link href="/" passHref>
								<a>
									<LogoPixii />
								</a>
							</Link>
						) : (
							<LogoPixii />
						)}
					</HeaderLogo>
					{menu && <MainMenu mainMenu={menu} onClick={() => setIsMenuOpened(!isMenuOpened)} />}
				</RowMain>
			</Header>
			{!!submenu?.length && <Submenu items={submenu} condensed={isScrolled} />}
			{children}
			{displayFooterMenu && menu && <PageFooterMenu footer={menu} />}
		</Page>
	);
};
