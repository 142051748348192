import { FC, createElement } from 'react';
import { VideoSection } from '../ui/Video/VideoSection';

type Props = {
	language: string;
	value: string;
};
export const renderCode: FC<Props> = ({ language, value }) => {
	if (language === 'video') {
		const youtubeId = getYouTubeId(value);
		return youtubeId ? (
			<div className="video-container">
				<iframe
					allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					src={`https://www.youtube.com/embed/${youtubeId}?autoplay=0`}
					className="fullsize"
				/>
			</div>
		) : (
			<a href={value} target="_blank">
				{value}
			</a>
		);
	}
	const className = language && `language-${language}`;
	const code = createElement('code', className ? { className: className } : null, value);
	return createElement('pre', {}, code);
};

/**
 * Get YouTube ID from various YouTube URL
 * @author: takien
 * @url: http://takien.com
 */

function getYouTubeId(url: string) {
	const urls = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
	if (urls[2] !== undefined) {
		return urls[2].split(/[^0-9a-z_\-]/i)[0];
	}
	return undefined;
}
