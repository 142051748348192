import React, { FC } from 'react';

type Props = {
	title?: string;
};

export const HeaderLogo: FC<Props> = ({ title, children }) => {
	return (
		<p className="header__logo">
			{title && <span className="u-vhide">{title}</span>}
			{children}
		</p>
	);
};
