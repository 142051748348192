exports.DOMAINS = [
	{
		domain: 'pixii.sk',
		defaultLocale: 'sk',
		locales: ['cs'],
	},
	{
		domain: 'pixii.hu',
		defaultLocale: 'hu',
	},
];
