import { SyntheticEvent } from 'react';
import { GetPageQuery } from '../../types/codegen';
import { MenuMain } from '../ui/layout/MenuMain';
import { MenuMainItem } from '../ui/layout/MenuMainItem';
import { MenuMainLink } from '../ui/layout/MenuMainLink';
import { MenuItem } from './MenuItem';

type Props = {
	mainMenu: GetPageQuery['mainMenu'];
	onClick?: (e: SyntheticEvent) => void;
};

export const MainMenu = ({ mainMenu, onClick }: Props) => {
	return (
		<MenuMain onClick={onClick}>
			{mainMenu?.menu?.map((item) => (
				<MenuItem
					item={item}
					key={item?.id}
					menuItemComponent={MenuMainItem}
					menuItemLinkComponent={MenuMainLink}
				/>
			))}
		</MenuMain>
	);
};
