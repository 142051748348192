import ReactMarkdown, { ReactMarkdownProps } from 'react-markdown';
import renderHeadingAnchor from '../common/renderHeadingAnchor';
import { renderCode } from '../common/renderCode';

type Props = {
	source?: string | null;
} & Omit<ReactMarkdownProps, 'source'>;

export const Markdown = ({ source, ...props }: Props) => {
	return source ? (
		<ReactMarkdown
			source={source}
			renderers={{ heading: renderHeadingAnchor, code: renderCode }}
			linkTarget={linkTargetResolver}
			{...props}
		/>
	) : null;
};

const linkTargetResolver = (uri: string) => {
	return uri.startsWith('http') ? '_blank' : '_self';
};
