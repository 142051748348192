import React, { FC, AnchorHTMLAttributes, forwardRef, RefObject } from 'react';

type Props = {
	isActive?: boolean;
	ref?: RefObject<HTMLAnchorElement> | ((instance: HTMLAnchorElement | null) => void) | null;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const FooterMenuLink: FC<Props> = forwardRef(({ children, isActive, ...anchorProps }, ref) => {
	if (isActive)
		return (
			<a className="m-footer__link" ref={ref} {...anchorProps}>
				<strong>{children}</strong>
			</a>
		);

	return (
		<a className="m-footer__link" ref={ref} {...anchorProps}>
			{children}
		</a>
	);
});
