import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
	isCondensed?: boolean;
};

export const MenuSecondary: FC<Props> = ({ children, isCondensed }) => {
	const className = classNames('m-secondary', isCondensed && 'is-condensed');
	return (
		<div className={className}>
			<ul className="m-secondary__list">{children}</ul>
		</div>
	);
};
