import React, { ReactNode, ReactElement } from 'react';

const flatten = (text: string, child: any): any => {
	return typeof child === 'string'
		? text + child
		: React.Children.toArray(child.props.children).reduce(flatten, text);
};

/**
 * renderHeadingAnchor is a custom renderer
 * It parses the heading and attaches an id to it to be used as an anchor
 */
type Props = {
	children: ReactElement;
	level: 1 | 2 | 3 | 4 | 5 | 6;
};
const renderHeadingAnchor = (props: Props) => {
	const children = React.Children.toArray(props.children);
	const text = children.reduce(flatten, '');
	const slug = text
		.toLowerCase()
		.replace(/\W/g, '-')
		.split('-')
		.filter(Boolean)
		.join('-');
	return React.createElement('h' + props.level, {} , props.children,  React.createElement('a', {id: slug, style: {position: 'relative', top: -145}}, ''));
};

export default renderHeadingAnchor;
