import React, { FC, SyntheticEvent } from 'react';

type Props = { onClick?: (e: SyntheticEvent) => void };

export const MenuMain: FC<Props> = ({ onClick, children }) => {
	return (
		<nav className="m-main">
			<a href="#" onClick={onClick} className="m-main__tool">
				<span className="m-main__tool-icon"></span>
				<span className="u-vhide">Menu</span>
			</a>
			<ul className="m-main__list" onClick={onClick}>
				{children}
			</ul>
		</nav>
	);
};
