import Link from 'next/link';
import { useRouter } from 'next/router';
import { ElementType } from 'react';
import { MainMenuFragmentFragment } from '../../types/codegen';
import { SubmenuItem } from '../ui/layout/SubmenuItem';
import { SubmenuLink } from '../ui/layout/SubmenuLink';
import { SubmenuList } from '../ui/layout/SubmenuList';

const systemPagesMap = {
	index: '/',
};

type MenuItemProps = {
	item:
		| NonNullable<NonNullable<MainMenuFragmentFragment['mainMenu']>['menu']>[0]
		| NonNullable<
				NonNullable<NonNullable<NonNullable<MainMenuFragmentFragment['mainMenu']>['menu']>[0]>['submenu']
		  >[0];
	menuItemComponent: ElementType;
	menuItemLinkComponent: ElementType;
};

export const MenuItem = ({ item, menuItemComponent, menuItemLinkComponent }: MenuItemProps) => {
	const MenuItemComponent = menuItemComponent;
	const MenuItemLinkComponent = menuItemLinkComponent;
	const router = useRouter();

	const submenu =
		item && 'submenu' in item && item.submenu != null && item.submenu.length > 0 ? (
			<SubmenuList>
				{item?.submenu?.map((subMenu) => (
					<MenuItem
						item={subMenu}
						key={subMenu?.id}
						menuItemComponent={SubmenuItem}
						menuItemLinkComponent={SubmenuLink}
					/>
				))}
			</SubmenuList>
		) : null;

	if (item) {
		if (item.page) {
			return (
				<MenuItemComponent variant={submenu ? 'submenu' : undefined}>
					<Link href="/[page]" as={`/${item?.page?.slug}`} passHref>
						<MenuItemLinkComponent isActive={item?.page?.slug && router.asPath.includes(item.page.slug)}>
							{item.title || item.page.title}
						</MenuItemLinkComponent>
					</Link>
					{submenu}
				</MenuItemComponent>
			);
		} else if (item.systemPage) {
			return (
				<MenuItemComponent variant={submenu ? 'submenu' : undefined}>
					<Link href={systemPagesMap[item.systemPage]} passHref>
						<MenuItemLinkComponent isActive={router.asPath.includes(systemPagesMap[item.systemPage])}>
							{item.title}
						</MenuItemLinkComponent>
					</Link>
					{submenu}
				</MenuItemComponent>
			);
		} else if (item.externalPage) {
			return (
				<MenuItemComponent variant={submenu ? 'submenu' : undefined}>
					<MenuItemLinkComponent href={item.externalPage}>{item.title}</MenuItemLinkComponent>
					{submenu}
				</MenuItemComponent>
			);
		} else {
			return (
				<MenuItemComponent variant={submenu ? 'submenu' : item.title ? undefined : 'separator'}>
					{item.title && (
						<MenuItemLinkComponent
							isActive={
								'submenu' in item &&
								item.submenu?.some(
									(submenuItem) =>
										submenuItem?.page?.slug && router.asPath.includes(submenuItem.page.slug)
								)
							}
						>
							{item.title}
						</MenuItemLinkComponent>
					)}
					{submenu}
				</MenuItemComponent>
			);
		}
	}
	return null;
};
