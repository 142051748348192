import { FC } from 'react';
import classNames from 'classnames';

type Props = {
	variants?: 'xl';
};

export const RowMain: FC<Props> = ({ children, variants }) => {
	const className = classNames('row-main', variants && `row-main--${variants}`);
	return <div className={className}>{children}</div>;
};
