import { FC } from 'react';
import classNames from 'classnames';

type Props = {
	isMenuOpened?: boolean;
};

export const Page: FC<Props> = ({ children, isMenuOpened }) => {
	const className = classNames('page', isMenuOpened ? 'menu-opened' : false);
	return <div className={className}>{children}</div>;
};
