import React, { FC, SyntheticEvent } from 'react';

type Props = { onClick?: (e: SyntheticEvent) => void };

export const SubmenuList: FC<Props> = ({ onClick, children }) => {
	return (
		<ul className="m-sub" onClick={onClick}>
			{children}
		</ul>
	);
};
