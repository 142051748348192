import React, { FC, AnchorHTMLAttributes, forwardRef, RefObject } from 'react';
import classNames from 'classnames';

type Props = {
	isActive?: boolean;
	ref?: RefObject<HTMLAnchorElement> | ((instance: HTMLAnchorElement | null) => void) | null;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const MenuSecondaryLink: FC<Props> = forwardRef(({ children, isActive = false, ...anchorProps }, ref) => {
	const classes = classNames('m-secondary__link', isActive ? 'is-active' : '');

	return (
		<a className={classes} ref={ref} {...anchorProps}>
			{children}
		</a>
	);
});
