import { GetPageQuery } from '../../types/codegen';
import { FooterLogo } from '../ui/footer/FooterLogo';
import { FooterMenu as FooterMenuUi } from '../ui/footer/FooterMenu';
import { FooterMenuItem } from '../ui/footer/FooterMenuItem';
import { FooterMenuLink } from '../ui/footer/FooterMenuLink';
import { FooterMenuList } from '../ui/footer/FooterMenuList';
import { LogoPixii } from '../ui/icons/LogoPixii';
import { Footer } from '../ui/layout/Footer';
import { MenuItem } from './MenuItem';

type Props = {
	footer: GetPageQuery['mainMenu'];
};

export const PageFooterMenu = ({ footer }: Props) => {
	return (
		<Footer>
			<FooterLogo>
				<LogoPixii />
			</FooterLogo>

			{footer?.menu && (
				<FooterMenuUi>
					{footer.menu
						.filter((menu) => menu?.submenu?.length)
						.map((menu) => (
							<FooterMenuList key={menu?.id}>
								{menu?.submenu?.map((subMenu) => (
									<MenuItem
										item={subMenu}
										key={subMenu?.id}
										menuItemComponent={FooterMenuItem}
										menuItemLinkComponent={FooterMenuLink}
									/>
								))}
							</FooterMenuList>
						))}

					<FooterMenuList>
						{footer.menu
							.filter((menu) => menu?.page?.slug)
							.map((menu) => (
								<MenuItem
									item={menu}
									key={menu?.id}
									menuItemComponent={FooterMenuItem}
									menuItemLinkComponent={FooterMenuLink}
								/>
							))}
					</FooterMenuList>
				</FooterMenuUi>
			)}
		</Footer>
	);
};
