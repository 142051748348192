import { useContext, useMemo } from 'react';
import { createTrackFn, EventTrackerContext } from './eventTracker';

export const useEventTracker = () => {
	const trackers = useContext(EventTrackerContext);

	const tracker = useMemo(() => {
		const trackPageView = createTrackFn(trackers, 'trackPageView');
		const trackEvent = createTrackFn(trackers, 'trackEvent');
		const trackData = createTrackFn(trackers, 'trackData');

		return {
			trackPageView,
			trackEvent,
			trackData,
		};
	}, [trackers]);

	return tracker;
};

export type EventTracker = ReturnType<typeof useEventTracker>;
