import { FC, Children } from 'react';

type Props = {};

export const FooterMenu: FC<Props> = ({ children }) => {
	return (
		<div className="m-footer">
			{Children.map(children, child => (
				<div className="m-footer__items">{child}</div>
			))}
		</div>
	);
};
