import { createContext } from 'react';

export const EventTrackerContext = createContext<Tracker[]>([]);

type TrackPageViewArgs = { url: string; title: string };
type TrackPageView = (args: TrackPageViewArgs) => void;

export type Tracker = {
	trackPageView?: TrackPageView;
	trackEvent?: (event: string, data: { [key: string]: any }) => void;
	trackData?: (data: { [key: string]: any }) => void;
};

export const createTrackFn = <T, K extends keyof T>(trackers: T[], fn: K): NonNullable<T[K]> => {
	const trackFn = (...args: any[]) => {
		return trackers.map(tracker => {
			if (typeof tracker[fn] === 'function') {
				return ((tracker[fn] as unknown) as Function)(...args);
			}
			return null;
		});
	};
	return (trackFn as unknown) as NonNullable<T[K]>;
};
