import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
	isLastItem?: boolean;
	variant?: 'submenu' | 'separator';
};

export const MenuMainItem: FC<Props> = ({ children, isLastItem = false, variant }) => {
	const classesItem = classNames('m-main__item', variant && `m-main__item--${variant}`, isLastItem && 'is-last-item');

	return <li className={classesItem}>{children}</li>;
};
