import { Maybe } from '../types/codegen';
import domains from './domains';

export const LOCALE_WITHOUT_SUFFIX = 'cs';

export const localize = <T>(obj: T, locale?: string) => {
	if (obj && locale) {
		const keys = Object.keys(obj);
		for (const key in obj) {
			if (locale !== LOCALE_WITHOUT_SUFFIX && key.endsWith(`_${locale}`)) {
				const originalKey = key.slice(0, -(locale.length + 1));
				if (keys.includes(originalKey)) {
					//@ts-ignore This requires the fields with localization suffix to be same type as the default ones
					obj[originalKey] = obj[key];
				}
			}
			if (key.endsWith('_sk') || key.endsWith('_hu')) {
				//@ts-ignore These fields can be null because they are not supposed to be used
				obj[key] = null;
			} else if (typeof obj[key] === 'object') {
				localize(obj[key], locale);
			}
		}
	}
	return obj;
};

export const langMap = new Map([
	['cs', 'CZ'],
	['sk', 'SK'],
	['hu', 'HU'],
]);

export const generateLangUrl = (
	lang: string,
	localizedSlugs?:
		| {
				locale: string;
				slug: Maybe<string>;
		  }[]
		| null
) => {
	const domain = domains.DOMAINS.find((domain) => domain.defaultLocale === lang || domain.locales?.includes(lang));
	const slug = localizedSlugs?.find((slug) => slug.locale === lang)?.slug || '';
	return `${domain ? `https://${domain.domain}${domain?.locales?.includes(lang) ? `/${lang}` : ''}` : ''}/${slug}`;
};
